import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-791bbfbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["points", "data-type", "data-id"]
const _hoisted_2 = ["rx", "ry", "cx", "cy", "data-type", "data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tag === 'polygon')
    ? (_openBlock(), _createElementBlock("polygon", {
        key: 0,
        points: _ctx.points,
        "data-type": _ctx.type,
        "data-id": _ctx.id,
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.$emit('click', e))
      }, null, 8, _hoisted_1))
    : (_ctx.tag === 'ellipse')
      ? (_openBlock(), _createElementBlock("ellipse", {
          key: 1,
          rx: _ctx.rx,
          ry: _ctx.ry,
          cx: _ctx.cx,
          cy: _ctx.cy,
          "data-type": _ctx.type,
          "data-id": _ctx.id,
          onClick: _cache[1] || (_cache[1] = (e) => _ctx.$emit('click', e))
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
}