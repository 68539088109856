import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05d46075"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "editor" }
const _hoisted_2 = { class: "navbar navbar-expand navbar-light bg-light" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "navbar-nav w-100 justify-content-around" }
const _hoisted_5 = ["data-id"]
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "form-select" }
const _hoisted_8 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hold = _resolveComponent("Hold")!
  const _component_Wall = _resolveComponent("Wall")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries({
              select: 'Wand auswählen (W)',
              ellipse: 'Ellipse hinzufügen (A)',
              polygon: 'Polygon hinzufügen (S)',
              move: 'Griff bewegen (D)',
              delete: 'Griff löschen (F)',
            }), ([k, v]) => {
              return (_openBlock(), _createElementBlock("li", {
                key: k,
                class: _normalizeClass(["nav-item py-2", { active: k === _ctx.mode }])
              }, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: k === _ctx.mode }]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNavClick && _ctx.onNavClick(...args)), ["prevent"])),
                  href: "#",
                  "data-id": k
                }, _toDisplayString(v), 11, _hoisted_5)
              ], 2))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.segment != null)
          ? (_openBlock(), _createBlock(_component_Wall, {
              key: 0,
              data: _ctx.holds,
              types: _ctx.holdTypes,
              "refresh-arrows": _ctx.refreshWall,
              "editor-mode": true,
              onMousedown: _ctx.onMouseDown,
              onMousemove: _ctx.onMouseMove,
              onMouseup: _ctx.onMouseUp,
              onContextmenu: _ctx.onRightClick
            }, {
              dynamicHolds: _withCtx(() => [
                (_ctx.dynamicHold)
                  ? (_openBlock(), _createBlock(_component_Hold, {
                      key: 0,
                      id: _ctx.dynamicHold.id,
                      tag: _ctx.dynamicHold.tag,
                      attr: _ctx.dynamicHold.attr,
                      type: _ctx.dynamicHold.type
                    }, null, 8, ["id", "tag", "attr", "type"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data", "types", "refresh-arrows", "onMousedown", "onMousemove", "onMouseup", "onContextmenu"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_b_modal, {
      id: "wallSelectionModal",
      title: "Segment auswählen",
      "ok-title": "Auswählen",
      "cancel-title": "Abbrechen",
      onOk: _ctx.selectWallSegment
    }, {
      default: _withCtx(() => [
        _createElementVNode("select", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (s) => {
            return (_openBlock(), _createElementBlock("option", {
              key: s,
              value: s,
              selected: _ctx.segment === s
            }, _toDisplayString(s), 9, _hoisted_8))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["onOk"])
  ], 64))
}