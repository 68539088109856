
import { defineComponent } from "vue";

// Fix until TypeScript libraries support this natively
interface SubmitEvent extends Event {
  submitter: HTMLElement;
}

export default defineComponent({
  name: "Login",
  props: {
    registerMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["login"],
  data() {
    return {
      name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    emitLogin(e: SubmitEvent): void {
      this.$emit("login", this.email, this.password, this.name, e.submitter.id);
    },
  },
});
