import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    id: _ctx.id,
    tabindex: "-1"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog", _ctx.modalDialogClass])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": _ctx.headerCloseLabel
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (!_ctx.hideFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (!_ctx.okOnly)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass(["btn", _ctx.cancelClass]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.bControls.hideModal(_ctx.id);
              _ctx.$emit('cancel');
            })
                  }, _toDisplayString(_ctx.cancelTitle), 3))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn", _ctx.okClass]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.bControls.hideModal(_ctx.id);
              _ctx.$emit('ok');
            })
              }, _toDisplayString(_ctx.okTitle), 3)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 8, _hoisted_1))
}