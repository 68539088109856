import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-072814b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statsComputed.changelog, (change) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-12 mb-3",
        key: change.version
      }, [
        _createElementVNode("h5", null, [
          _createTextVNode(" DBS " + _toDisplayString(change.version) + " vom ", 1),
          _createElementVNode("em", null, _toDisplayString(change.date), 1)
        ]),
        _createElementVNode("p", _hoisted_2, _toDisplayString(change.description), 1),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(change.changes, (detail, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(detail), 1))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}