
import { defineComponent } from "vue";
import { Hold as HoldType } from "@/api/types";
import Hold from "@/components/wall/Hold.vue";

export default defineComponent({
  name: "WallSegment",
  emits: ["click"],
  components: { Hold },
  props: {
    image: {
      type: String,
      required: true,
    },
    holds: {
      type: Array as () => HoldType[],
      required: true,
    },
    types: {
      type: Object as () => { [holdId: number]: 0 | 1 | 2 },
      required: true,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    holdsFiltered(): HoldType[] {
      if (this.refresh) true;
      return this.holds;
    },
  },
});
