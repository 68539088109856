
import { defineComponent } from "vue";
import { Boulder } from "@/api/types";
import { gradeItoa } from "@/types/grades";
import Stars from "@/components/Stars.vue";

export default defineComponent({
  name: "SearchResult",
  components: { Stars },
  emits: ["click"],
  props: {
    data: {
      type: Object as () => Boulder,
      required: true,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    gItoa(grade: number): string {
      return gradeItoa(grade);
    },
  },
});
