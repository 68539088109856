
import { defineComponent } from "vue";
import Stars from "@/components/Stars.vue";
import { gradeAtoi, Grades } from "@/types/grades";
import ToggleMark from "@/components/ToggleMark.vue";
import { putBoulderOfTheDay, putClimbed, putVote } from "@/api/interface";

export default defineComponent({
  name: "BoulderInfo",
  components: { ToggleMark, Stars },
  model: {
    event: "delete",
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: String,
    description: String,
    creator: String,
    deletable: {
      type: Boolean,
      required: true,
    },
    grade: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    climbed: {
      type: Boolean,
      required: true,
    },
    boulderOfTheDay: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      botdClimbed: false,
      internalClimbed: false,
      internalGradeVote: 0,
      internalRatingVote: 0,
    };
  },
  created() {
    this.internalGradeVote = gradeAtoi(this.grade);
    this.internalRatingVote = this.rating;
    this.internalClimbed = this.climbed;
  },
  computed: {
    botdVote: {
      get(): boolean {
        return this.botdClimbed;
      },
      set(climbed: boolean) {
        this.botdClimbed = climbed;
        putBoulderOfTheDay({ climbed: this.botdClimbed });
      },
    },
    climbedVote: {
      get(): boolean {
        return this.internalClimbed;
      },
      set(climbed: boolean) {
        this.internalClimbed = climbed;
        putClimbed(this.id, { climbed: climbed });
      },
    },
    gradeVote: {
      get(): number {
        return this.internalGradeVote;
      },
      set(grade: number) {
        this.internalGradeVote = grade;
        putVote(this.id, { grade: grade });
      },
    },
    ratingVote: {
      get(): number {
        return this.internalRatingVote;
      },
      set(rating: number) {
        this.internalRatingVote = rating;
        putVote(this.id, { rating: rating });
      },
    },
    gradeOptions(): { text: string; value: number }[] {
      const options = [];
      for (const grade of Grades) {
        options.push({
          text: grade,
          value: gradeAtoi(grade),
        });
      }
      return options;
    },
  },
});
