
import { defineComponent } from "vue";

export default defineComponent({
  name: "Hold",
  props: {
    id: {
      type: Number,
      required: true,
    },
    tag: {
      type: String,
      required: true,
    },
    attr: {
      type: String,
      required: true,
    },
    type: {
      type: Number as () => 0 | 1 | 2,
      required: true,
    },
  },
  data() {
    return {
      parsedAttr: {} as { [attr: string]: string },
      parsedAttrInit: false,
    };
  },
  computed: {
    points(): string {
      this.lazyParse();
      return this.parsedAttr.points;
    },
    rx(): string {
      this.lazyParse();
      return this.parsedAttr.rx ?? "";
    },
    ry(): string {
      this.lazyParse();
      return this.parsedAttr.ry ?? "";
    },
    cx(): string {
      this.lazyParse();
      return this.parsedAttr.cx ?? "";
    },
    cy(): string {
      this.lazyParse();
      return this.parsedAttr.cy ?? "";
    },
  },
  methods: {
    lazyParse() {
      if (!this.parsedAttrInit) {
        this.parseAttr();
        this.parsedAttrInit = true;
      }
    },

    parseAttr() {
      const attr: { [attr: string]: string } = {};
      let state = 0;
      let token = "";
      let currentAttr = "";

      for (let i = 0; i < this.attr.length; i++) {
        const char = this.attr.charAt(i);
        const charCode = char.charCodeAt(0);
        switch (state) {
          case 0: // Outside
            if (charCode == 0x2d || (charCode > 0x40 && charCode <= 0x5a) || (charCode > 0x60 && charCode <= 0x7a)) {
              state = 1;
              token = char;
            }
            break;
          case 1: // Attr name
            if (charCode == 0x2d || (charCode > 0x40 && charCode <= 0x5a) || (charCode > 0x60 && charCode <= 0x7a)) {
              token += char;
            }
            if (charCode == 0x3d) {
              state = 2;
              currentAttr = token;
              token = "";
            }
            break;
          case 2: // Waiting for quotes
            if (charCode == 0x22) {
              state = 3;
            } else if (charCode == 0x27) {
              state = 4;
            }
            break;
          case 3: // Double quotes
            if (charCode != 0x22) {
              token += char;
            } else {
              state = 0;
              attr[currentAttr] = token;
              token = "";
            }
            break;
          case 4: // Single quotes
            if (charCode != 0x27) {
              token += char;
            } else {
              state = 0;
              attr[currentAttr] = token;
              token = "";
            }
            break;
        }
      }
      if (state != 0) {
        console.warn(`Parser failure for "${this.attr}"`);
      }

      this.parsedAttr = attr;
    },
  },
  watch: {
    attr() {
      this.parsedAttrInit = false;
    },
  },
});
