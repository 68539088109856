
import { defineComponent } from "vue";
import { gradeAtoi, Grades } from "@/types/grades";
import { BoulderNew } from "@/api/types";
import Stars from "@/components/Stars.vue";

export default defineComponent({
  name: "BoulderAddForm",
  components: { Stars },
  emits: ["submit", "cancel"],
  data() {
    return {
      name: "",
      description: "",
      grade: 10,
      rating: 1,
      grades: Grades,
    };
  },
  computed: {
    gradeOptions() {
      const options = [];
      for (const grade of Grades) {
        options.push({
          text: grade,
          value: gradeAtoi(grade),
        });
      }
      return options;
    },
  },
  methods: {
    submitHandlerWrapper() {
      const data: BoulderNew = {
        name: this.name,
        grade: this.grade,
        stars: +this.rating,
        holds: [],
      };
      this.$emit("submit", data);
    },
  },
});
