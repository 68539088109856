
import { defineComponent } from "vue";
import WallSegment from "./WallSegment.vue";
import { Holds } from "@/api/types";

export default defineComponent({
  name: "Wall",
  components: { WallSegment },
  emits: ["anyclick", "click", "indexchange"],
  props: {
    data: {
      type: Array as () => Holds[],
      required: true,
    },
    types: {
      type: Object as () => { [holdId: number]: 0 | 1 | 2 },
      required: true,
    },
    refreshArrows: {
      type: Boolean,
      default: false,
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentIndex: {
      get(): number {
        return this.internalCurrentIndex;
      },
      set(value: number) {
        this.internalCurrentIndex = value;
        this.$emit("indexchange", value);
      },
    },
    leftArrowClass() {
      if (this.refreshArrows) true;
      let blink = false;
      for (let i = this.internalCurrentIndex - 1; i >= 0; i--) {
        for (const hold of this.data[i].holds) {
          if (this.types[hold.id] != 0) {
            blink = true;
            break;
          }
        }
        if (blink) break;
      }
      return {
        blink: blink,
        "text-muted": this.internalCurrentIndex == 0,
      };
    },
    rightArrowClass() {
      if (this.refreshArrows) true;
      let blink = false;
      for (let i = this.internalCurrentIndex + 1; i < this.data.length; i++) {
        for (const hold of this.data[i].holds) {
          if (this.types[hold.id] != 0) {
            blink = true;
            break;
          }
        }
        if (blink) break;
      }
      return {
        blink: blink,
        "text-muted": this.internalCurrentIndex == this.data.length - 1,
      };
    },
  },
  data() {
    return {
      refreshSegments: false,
      internalCurrentIndex: 0,
    };
  },
  methods: {
    holdClickHandlerWrapper(id: number, e: Event) {
      this.$emit("click", id, e);
      this.refresh();
    },
    refresh(mainwall?: number) {
      if (mainwall !== undefined) {
        this.internalCurrentIndex = mainwall;
      }
      this.refreshSegments = !this.refreshSegments;
    },
    nextSegment() {
      if (this.currentIndex + 1 < this.data.length) this.currentIndex++;
    },
    prevSegment() {
      if (this.currentIndex - 1 >= 0) this.currentIndex--;
    },
  },
});
