
import { defineComponent } from "vue";

export default defineComponent({
  name: "Legal",
  setup() {
    return {
      name: process.env.VUE_APP_PERSONAL_NAME ?? "",
      street: process.env.VUE_APP_PERSONAL_STREET ?? "",
      pcode: process.env.VUE_APP_PERSONAL_PCODE ?? "",
      city: process.env.VUE_APP_PERSONAL_CITY ?? "",
      country: process.env.VUE_APP_PERSONAL_COUNTRY ?? "",
      email: process.env.VUE_APP_PERSONAL_EMAIL ?? "",
    };
  },
});
