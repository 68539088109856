
import { defineComponent, inject } from "vue";
import Wall from "@/components/wall/Wall.vue";
import Login from "@/components/Login.vue";
import Menu from "@/components/menu/Menu.vue";
import Ranking from "@/components/ranking/Ranking.vue";
import BoulderInfo from "@/components/BoulderInfo.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import BoulderAddForm from "@/components/forms/BoulderAddForm.vue";
import SearchForm from "@/components/forms/SearchForm.vue";
import Statistics from "@/components/Statistics.vue";
import BModal from "@/components/bootstrap/BModal.vue";
import {
  Boulder,
  BoulderNew,
  BoulderSearch,
  Holds,
  Ranking as RankingType,
  SearchResults as SearchResultsType,
  Stats,
} from "@/api/types";
import {
  deleteBoulder,
  getBoulder,
  getBoulderOfTheDay,
  getHolds,
  getRanking,
  getStats,
  getWall,
  loginPassword,
  logout,
  newBoulder,
  postRegistration,
  searchBoulder,
} from "@/api/interface";
import { gradeItoa } from "@/types/grades";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-solid-svg-icons";
import { setApiVue, setAuthentication } from "@/api";
import { bControls } from "@/plugins/BootstrapControls";
import { useToast } from "vue-toastification";
import { ProgressStatus } from "@/plugins/ProgressStatus";
import { max, min } from "@popperjs/core/lib/utils/math";

export default defineComponent({
  name: "Systemboard",
  components: {
    BModal,
    Statistics,
    SearchForm,
    BoulderAddForm,
    SearchResults,
    BoulderInfo,
    Ranking,
    Menu,
    Login,
    Wall,
  },
  setup() {
    let bControls = {} as bControls;
    const x: bControls | undefined = inject("bControls");
    if (x !== undefined) {
      bControls = x;
    } else {
      console.warn("Plugin bControls missing");
    }

    let progress = (() => true) as (status: ProgressStatus) => void;
    const y: ((status: ProgressStatus) => void) | undefined = inject("progress");
    if (y !== undefined) {
      progress = y;
    } else {
      console.warn("Plugin progress missing");
    }

    const toast = useToast();
    return {
      bControls,
      toast,
      progress,
    };
  },
  beforeMount() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setApiVue(this);
    if (this.isLoggedIn) {
      this.loadWall();
    }
    setTimeout(() => {
      this.mail = decodeURIComponent("%6d%61%69lto%3A%73y%73te%6dbo%61rd%40d%69g%69t%61lbre%61d.de");
    }, 1500);
  },
  data() {
    return {
      isLoggedIn: window.sessionStorage.getItem("auth") != null && window.sessionStorage.getItem("auth") != "Login",
      isGuest: window.sessionStorage.getItem("auth")?.toLowerCase() == "guest",
      isPrivileged: window.sessionStorage.getItem("privileged")?.toLowerCase() == "1",
      isSelectionMode: false,
      isBoulderOfTheDay: false,
      wall: undefined as typeof Wall | undefined,
      wallLoaded: false,
      wallData: [] as Holds[],
      holdTypes: {} as { [holdId: number]: 0 | 1 | 2 },
      searchResults: { results: [], pages: 0 } as SearchResultsType,
      searchResultsPage: 1,
      searchLastQuery: {} as BoulderSearch,
      searchResultsRefresh: false,
      showSearchResults: false,
      boulder: null as Boulder | null,
      boulderToDelete: undefined as number | undefined,
      ranking: [] as RankingType[],
      refreshArrows: false,
      showSubMenu: false,
      mail: "",
      stats: null as Stats | null,
      statsRefresh: false,
      registerMode: false,
    };
  },
  methods: {
    async loadWall() {
      if (!this.wallLoaded) {
        const wall = await getWall();
        if (!wall) return;
        this.wallData = (await getHolds(wall.id)) ?? [];

        this.holdTypes = {};
        this.clearWall();

        this.wallLoaded = true;
      }
    },

    async loadBoulderOfTheDay() {
      this.clearWall();
      this.boulder = (await getBoulderOfTheDay()) ?? null;
      this.isBoulderOfTheDay = true;
      this.showBoulder();
    },

    async loadBoulder(id: number) {
      this.clearWall();
      this.boulder = (await getBoulder(id)) ?? null;
      this.isBoulderOfTheDay = false;
      this.showBoulder();
    },

    showBoulder() {
      if (this.boulder) {
        this.isSelectionMode = false;
        if (!this.boulder.holds) {
          console.warn("Loaded boulder is missing the holds list", this.boulder);
          return;
        }
        for (const id in this.boulder.holds) {
          this.holdTypes[id] = this.boulder.holds[id];
        }
        this.refreshWall(true);
        document.querySelector(".wallWrapper")?.scrollIntoView({ behavior: "smooth" });
      }
    },

    async addBoulder(data: BoulderNew) {
      for (const id in this.holdTypes) {
        if (this.holdTypes[id] > 0) {
          data.holds.push({
            id: +id,
            type: this.holdTypes[id],
          });
        }
      }
      const response = await newBoulder(data);
      if (!response) return;
      await this.loadBoulder(response.id);
      this.isSelectionMode = false;
    },

    async searchBoulder(data: BoulderSearch) {
      this.bControls.hideModal("searchModal");
      this.searchResultsPage = data.page ?? 1;
      this.showSearchResults = true;
      this.searchLastQuery = data;
      this.searchResults = (await searchBoulder(data)) ?? { pages: 0, results: [] };
      document.querySelector(".boulderInfo")?.scrollIntoView({ behavior: "smooth" });
    },

    async loginHandler(email: string, password: string, name: string, type: string) {
      switch (type) {
        case "login":
          if (await loginPassword(email, password)) {
            this.isLoggedIn = true;
            this.isPrivileged = window.sessionStorage.getItem("privileged")?.toLowerCase() == "1";
            await this.loadWall();
          }
          break;
        case "guestlogin":
          setAuthentication("guest", false);
          this.isLoggedIn = true;
          await this.loadWall();
          break;
        case "register":
          if (!this.registerMode) {
            this.registerMode = true;
          } else {
            await postRegistration({
              name: name,
              email: email,
              password: password,
            });
            this.registerMode = false;
          }
          break;
        case "cancel":
          this.registerMode = false;
          break;
      }
    },

    async showRanking() {
      this.ranking = (await getRanking()) ?? [];
      this.bControls.showModal("rankingModal");
    },

    askForDeletion(id: number) {
      this.boulderToDelete = id;
      this.bControls.showModal("deleteModal");
    },

    reallyDeleteBoulder() {
      if (this.boulderToDelete) {
        deleteBoulder(this.boulderToDelete).then(() => {
          this.boulderToDelete = undefined;
          this.showSearchResults = false;
          this.boulder = null;
        });
      }
    },

    menuHandler(id: string) {
      switch (id) {
        case "latest":
          this.searchBoulder({});
          break;
        case "search":
          this.bControls.showModal("searchModal");
          break;
        case "add":
          this.boulder = null;
          this.clearWall();
          this.refreshWall();
          this.isSelectionMode = true;
          break;
        case "botd":
          this.loadBoulderOfTheDay();
          break;
        case "notdoneyet":
          this.searchBoulder({
            notDoneYet: true,
          });
          break;
        case "ranking":
          this.showRanking();
          break;
        case "other":
          this.showSubMenu = !this.showSubMenu;
          break;
        case "report":
          this.bControls.toggleCollapse("nav-collapse");
          this.showSubMenu = false;
          break;
        case "faq":
          this.bControls.toggleCollapse("nav-collapse");
          this.showSubMenu = false;
          this.bControls.showModal("faqModal");
          break;
        case "about":
          this.bControls.toggleCollapse("nav-collapse");
          this.showSubMenu = false;
          getStats().then((stats) => {
            this.stats = stats ?? null;
            this.statsRefresh = !this.statsRefresh;
            this.bControls.showModal("statsModal");
          });
          break;
        case "impressum":
          this.bControls.toggleCollapse("nav-collapse");
          this.showSubMenu = false;
          this.$router.push("/impressum");
          break;
        case "privacy":
          this.bControls.toggleCollapse("nav-collapse");
          this.showSubMenu = false;
          this.$router.push("/datenschutz");
          break;
        case "editor":
          this.bControls.toggleCollapse("nav-collapse");
          this.$router.push("/editor");
          break;
        case "logout":
          this.bControls.toggleCollapse("nav-collapse");
          logout().then(() => {
            this.isLoggedIn = false;
            this.isPrivileged = false;
          });
          break;
        default:
          console.warn(`No menu handler for menu id ${id}`);
      }
    },

    async paginationHandler(id: number) {
      if (id) {
        if (id == -1) {
          this.searchLastQuery.page = min(this.searchResultsPage + 1, this.searchResults.pages);
        } else if (id == -2) {
          this.searchLastQuery.page = max(this.searchResultsPage - 1, 1);
        } else {
          this.searchLastQuery.page = id;
        }
        await this.searchBoulder(this.searchLastQuery);
      }
    },

    clearWall() {
      this.boulder = null;
      for (const holds of this.wallData) {
        for (const hold of holds.holds) {
          this.holdTypes[hold.id] = 0;
        }
      }
    },

    refreshWall(jumpToMainWall = false) {
      if (!this.wall) {
        this.wall = this.$refs.wall as typeof Wall;
      }
      this.wall?.refresh(jumpToMainWall ? this.boulder?.location?.main : undefined);
      this.refreshArrows = !this.refreshArrows;
    },

    refreshSearchResults() {
      this.searchResultsRefresh = !this.searchResultsRefresh;
    },

    cancelSelectionMode() {
      this.isSelectionMode = false;
      this.clearWall();
      this.refreshWall();
    },

    holdClickHandler(id: number, e: Event) {
      e.stopPropagation();
      const holdTypes = this.holdTypes;
      if (this.isSelectionMode) {
        if (holdTypes[id] == 2) holdTypes[id] = 0;
        else holdTypes[id] += 1;

        this.holdTypes = holdTypes;
      }
    },

    gItoa(grade: number): string {
      return gradeItoa(grade);
    },

    openExternal(url: string) {
      window.open(url, "_blank");
    },
  },
});
