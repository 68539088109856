import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-2 badge badge-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString(_ctx.rank), 1),
    _createElementVNode("td", null, [
      _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
      (typeof _ctx.badge == 'string' && _ctx.badge.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.badge), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, _toDisplayString(+_ctx.points), 1)
  ]))
}