
import { defineComponent } from "vue";

export default defineComponent({
  name: "RankingItem",
  props: {
    rank: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    badge: String,
    points: {
      type: Number,
      required: true,
    },
  },
});
