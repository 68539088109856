import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e03860f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick", "onMouseenter"]
const _hoisted_2 = { class: "positive fas fa-star" }
const _hoisted_3 = { class: "negative far fa-star" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stars", { dynamic: _ctx.dynamic }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.starArray, (star) => {
      return (_openBlock(), _createElementBlock("span", {
        key: star.id,
        class: _normalizeClass('star-' + star.id),
        onClick: ($event: any) => (_ctx.clickHandler(star.id)),
        onMouseenter: ($event: any) => (_ctx.mouseenterHandler(star.id)),
        onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseleaveHandler && _ctx.mouseleaveHandler(...args)))
      }, [
        _withDirectives(_createElementVNode("i", _hoisted_2, null, 512), [
          [_vShow, star.selected]
        ]),
        _withDirectives(_createElementVNode("i", _hoisted_3, null, 512), [
          [_vShow, !star.selected]
        ])
      ], 42, _hoisted_1))
    }), 128))
  ], 2))
}