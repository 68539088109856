
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToggleMark",
  emits: ["input"],
  props: {
    initialState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: false,
      animationActive: false,
    };
  },
  created() {
    this.state = this.initialState;
  },
  computed: {
    value: {
      get(): number {
        return this.state ? 1 : 0;
      },
      set(value: number) {
        this.state = !!value;
        this.$emit("input", this.state);
      },
    },
    animationClass() {
      return {
        active: this.animationActive,
      };
    },
  },
  methods: {
    clickHandler() {
      this.state = !this.state;
      this.$emit("input", this.state);
      this.animationActive = this.state;
    },
  },
});
