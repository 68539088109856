
import { defineComponent, inject } from "vue";
import SubMenu from "@/components/menu/SubMenu.vue";
import MenuItem from "@/components/menu/MenuItem.vue";
import { MenuItemData } from "@/types/MenuItemData";
import { bControls } from "@/plugins/BootstrapControls";

export default defineComponent({
  name: "Menu",
  components: {
    SubMenu,
    MenuItem,
  },
  emits: ["input", "click"],
  props: {
    menuData: {
      type: Array as () => MenuItemData[],
      required: true,
    },
    showSubMenu: {
      type: Boolean,
      required: true,
    },
    subMenuData: {
      type: Array as () => MenuItemData[],
      required: true,
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    let bControls = {} as bControls;
    const x: bControls | undefined = inject("bControls");
    if (x !== undefined) {
      bControls = x;
    }
    return {
      bControls,
    };
  },
  data() {
    return {
      internalCollapseVisible: false,
    };
  },
  computed: {
    value: {
      get(): boolean {
        return this.internalCollapseVisible;
      },
      set(val: boolean) {
        this.internalCollapseVisible = val;
        this.$attrs.value = val ? "true" : "";
      },
    },
    computedMenuData() {
      if (this.refresh) true;
      return this.menuData;
    },
    computedSubmenuData() {
      if (this.refresh) true;
      return this.subMenuData;
    },
  },
  methods: {
    toggleCollapse() {
      this.bControls.toggleCollapse("nav-collapse");
    },
  },
});
