import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01b05530"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["navbar submenu navbar-secondary navbar-light navbar-expand", _ctx.navClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subMenuData, (data, index) => {
        return (_openBlock(), _createElementBlock("ul", {
          key: index,
          class: "navbar-nav"
        }, [
          _createVNode(_component_MenuItem, {
            id: data.id,
            onClick: ($event: any) => (_ctx.$emit('click', data.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(data.label), 1)
            ]),
            _: 2
          }, 1032, ["id", "onClick"])
        ]))
      }), 128))
    ])
  ], 2))
}