
import { defineComponent } from "vue";
import { Stats } from "@/api/types";

export default defineComponent({
  name: "Statistics",
  props: {
    stats: Object as () => Stats,
    refresh: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    statsComputed(): Stats {
      // eslint-disable-next-line
      if (this.refresh) true;
      if (!this.stats) return { version: "", changelog: [] };
      return this.stats;
    },
  },
});
