import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e83d18c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1000 670"
}
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = { class: "holds" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hold = _resolveComponent("Hold")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wallSegment w-100", { hidden: !_ctx.visible }])
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("image", {
        x: "0",
        y: "0",
        width: "1000px",
        height: "670px",
        "xlink:href": _ctx.image,
        onDragstart: ($event: any) => (false)
      }, null, 40, _hoisted_2),
      _createElementVNode("g", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.holdsFiltered, (hold) => {
          return (_openBlock(), _createBlock(_component_Hold, {
            key: hold.id,
            id: hold.id,
            tag: hold.tag,
            attr: hold.attr,
            type: _ctx.types[hold.id],
            onClick: (e) => _ctx.$emit('click', hold.id, e)
          }, null, 8, ["id", "tag", "attr", "type", "onClick"]))
        }), 128)),
        _renderSlot(_ctx.$slots, "dynamicHolds", {}, undefined, true)
      ])
    ]))
  ], 2))
}