
import { defineComponent } from "vue";
import { gradeAtoi, Grades } from "@/types/grades";
import { BoulderSearch } from "@/api/types";
import Stars from "@/components/Stars.vue";

export default defineComponent({
  name: "SearchForm",
  components: { Stars },
  emits: ["submit"],
  data() {
    return {
      name: "",
      creator: "",
      minRating: 1,
      maxRating: 5,
      ratingSelection: "all",
      gradeSelection: "all",
      minGrade: 0,
      maxGrade: 0,
    };
  },
  computed: {
    gradeOptions(): { text: string; value: number }[] {
      const options = [];
      for (const grade of Grades) {
        options.push({
          text: grade,
          value: gradeAtoi(grade),
        });
      }
      return options;
    },
  },
  methods: {
    submitWrapper() {
      const data: BoulderSearch = {};
      if (this.name) data.name = this.name;
      if (this.creator) data.creator = this.creator;
      if (this.gradeSelection == "limited") {
        data.minGrade = this.minGrade;
        data.maxGrade = this.maxGrade;
      }
      if (this.ratingSelection == "limited") {
        data.minRating = +this.minRating;
        data.maxRating = +this.maxRating;
      }
      this.$emit("submit", data);
    },
  },
});
