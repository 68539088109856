
import { defineComponent, inject } from "vue";
import { bControls } from "@/plugins/BootstrapControls";

export default defineComponent({
  name: "BModal",
  emits: ["cancel", "ok"],
  props: {
    title: String,
    id: String,
    hideFooter: {
      type: Boolean,
      default: false,
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    okTitle: {
      type: String,
      default: "OK",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
    headerCloseLabel: {
      type: String,
      default: "Close",
    },
    size: {
      type: String as () => "sm" | "md" | "lg" | "xl",
      default: "md",
    },
    cancelVariant: {
      type: String,
      default: "secondary",
    },
    okVariant: {
      type: String,
      default: "primary",
    },
  },
  setup() {
    let bControls = {} as bControls;
    const x: bControls | undefined = inject("bControls");
    if (x !== undefined) {
      bControls = x;
    } else {
      console.warn("Plugin bControls missing");
    }
    return {
      bControls,
    };
  },
  computed: {
    modalDialogClass() {
      return {
        "modal-sm": this.size == "sm",
        "modal-lg": this.size == "lg",
        "modal-xl": this.size == "xl",
      };
    },
    okClass() {
      const variant = `btn-${this.okVariant}`;
      const classes = {} as { [clazz: string]: boolean };
      classes[variant] = true;
      return classes;
    },
    cancelClass() {
      const variant = `btn-${this.cancelVariant}`;
      const classes = {} as { [clazz: string]: boolean };
      classes[variant] = true;
      return classes;
    },
  },
});
