
import { defineComponent } from "vue";

export default defineComponent({
  name: "Stars",
  props: {
    dynamic: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    initialValue: {
      type: Number,
      default: 0,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["input"],
  data() {
    return {
      internalValue: 0,
      hoverValue: -1,
    };
  },
  created() {
    this.internalValue = this.initialValue;
  },
  computed: {
    starArray(): { id: number; selected: boolean }[] {
      if (this.updater()) true;
      const stars = [];
      for (let i = 0; i < this.count; i++) {
        stars.push({
          id: i,
          selected: !this.dynamic || this.hoverValue < 0 ? i < this.value : i < this.hoverValue,
        });
      }
      return stars;
    },
    value: {
      get(): number {
        return this.internalValue;
      },
      set(value: number) {
        this.internalValue = value;
        this.$emit("input", this.internalValue);
      },
    },
  },
  methods: {
    updater(): number {
      if (this.refresh) true;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.dynamic) this.internalValue = this.initialValue;
      return this.internalValue;
    },
    clickHandler(id: number) {
      if (this.dynamic) {
        this.internalValue = id + 1;
        this.$emit("input", this.internalValue);
      }
    },
    mouseenterHandler(id: number) {
      this.hoverValue = id + 1;
    },
    mouseleaveHandler() {
      this.hoverValue = -1;
    },
  },
});
