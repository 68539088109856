
import { defineComponent } from "vue";
import MenuItem from "@/components/menu/MenuItem.vue";
import { MenuItemData } from "@/types/MenuItemData";

export default defineComponent({
  name: "SubMenu",
  components: { MenuItem },
  emits: ["click"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    subMenuData: {
      type: Array as () => MenuItemData[],
      required: true,
    },
  },
  computed: {
    navClasses() {
      return { hidden: !this.show };
    },
  },
});
