
import { defineComponent } from "vue";
import RankingItem from "./RankingItem.vue";
import { Ranking } from "@/api/types";

export default defineComponent({
  name: "Ranking",
  components: { RankingItem },
  props: {
    rankingItems: {
      type: Array as () => Ranking[],
      required: true,
    },
  },
});
