import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ede4d5b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-item text-center p-2" }
const _hoisted_2 = {
  key: 1,
  class: "d-none d-lg-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      class: "nav-link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
        (e) => {
          if (_ctx.id === 'other') e.stopPropagation();
          _ctx.$emit('click', _ctx.id);
        }
      , ["prevent"])),
      href: "#"
    }, [
      (_ctx.icon !== undefined)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([_ctx.iconClass, "mr-3 mr-lg-0"])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.icon !== undefined)
        ? (_openBlock(), _createElementBlock("br", _hoisted_2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}