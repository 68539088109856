
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuItem",
  emits: ["click"],
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: String,
  },
  computed: {
    iconClass() {
      if (this.icon === undefined) return {};
      const classes: { [clazz: string]: boolean } = {};
      classes["fas"] = true;
      classes["fa-lg"] = true;
      classes["fa-" + this.icon] = true;
      return classes;
    },
  },
});
